.classe-item {
	.classe-image {
		position: relative;
	}

	.classe-meta {
		background: $white;
		display: flex;
		padding: 6px 16px;
		position: absolute;
		bottom: 0;
		left: 20px;
		transform: skew(-20deg);

		li {
			transform: skew(20deg);
			position: relative;

			a {
				font-size: 12px;
				font-weight: 600;
			}

		}

	}

}
