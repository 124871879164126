/*****************************
    Typography
*****************************/
body {
	font-family: $font-base;
	font-weight: 500;
	font-style: normal;
	font-size: 14px;
	color: $body-color;
	overflow-x: hidden;
}

a {
	outline: medium none !important;
	color: $primary;
	transition: all 0.3s ease-in-out;
	text-decoration: none !important;

	&:focus {
		color: $primary;
		text-decoration: none !important;
	}

	&:hover {
		color: $primary;
		text-decoration: none !important;
	}

}

input {
	outline: medium none !important;
	color: $primary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-hedding;
	font-weight: normal;
	color: $gray-8;
	margin-top: 0px;
	font-weight: 600;
	text-transform: capitalize;

	a {
		color: inherit;
	}

}

label {
	font-weight: normal;
}

h1 {
	font-size: 58px;
	font-style: normal;
	font-weight: 700;
}

h2 {
	font-size: 48px;
	font-weight: 700;
}

h3 {
	font-size: 30px;
	font-weight: 700;
}

h4 {
	font-size: 26px;
}

h5 {
	font-size: 22px;
}

h6 {
	font-size: 18px;
}

p {
	font-weight: normal;
	line-height: 1.5;
}

*::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::-moz-selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

::selection {
	background: $primary;
	color: $white;
	text-shadow: none;
}

/* container-fluid */
.container-fluid.container-space {
	padding: 0 100px;
}

/* form-control */
.form-control {
	border: 1px solid $border-color;
	border-radius: 0px;
	height: 50px;
	padding: 10px 20px;
	box-shadow: none;
	font-size: 14px;
	font-weight: 500;
	color: $gray-3;
	background-clip: inherit !important;

	&:focus {
		box-shadow: none;
		border-color: $primary;
	}

}

.form-control::-moz-placeholder {
	color: $gray-5;
}

.form-control::-ms-input-placeholder {
	color: $gray-5;
}

.form-control::-webkit-input-placeholder {
	color: $gray-5;
}
textarea.form-control {
    height: auto;
}

/* custom-file */
.custom-file {
	height: 50px;

	.custom-file-input {
		height: 50px;
		color: $gray-8;
		border-radius: $border-radius;
		border-color: $border-color;

		&:focus {
			box-shadow: none;
			border-color: $primary;
		}

	}

	.custom-file-label {
		height: 50px;
		font-size: 14px;
		font-weight: 600;
		color: $gray-8;
		border-color: $border-color;
		padding: 15px 90px 15px 20px;
		border-radius: $border-radius;

		&:after {
			height: 48px;
			padding: 14px 25px;
		}

	}

}

.custom-file-input:focus~.custom-file-label {
	box-shadow: none;
	border-color: $primary;
}

/* input-group-prepend */
.input-group-prepend,
.input-group-append {
	.input-group-text {
		width: 50px;
		background: transparent;
		border-color: $border-color;
		box-shadow: none;
		justify-content: center;
	}

}

/* checkbox */
.custom-control-input {
	&:checked~.custom-control-label {
		&:before {
			background: $primary;
			border-color: $primary;
		}

	}

	&:not(:disabled) {
		&:active~.custom-control-label {
			&:before {
				background: transparent;
				border-color: transparent;
			}

		}

	}

	&:focus~.custom-control-label {
		&:before {
			box-shadow: none;
		}

	}

	&:focus {
		&:not(:checked)~.custom-control-label {
			&:before {
				border-color: $border-color;
			}

		}

	}

}

.custom-control-label {
	&:before {
		top: 2px;
	}

	&:after {
		top: 2px;
	}

}

.custom-checkbox {
	.custom-control-label {
		&:before {
			border-radius: $border-radius;
			border: 2px solid $gray-2;
		}

	}

}

/* border  */
.border {
	border-color: $border-color !important;
}

.border-top {
	border-top-color: $border-color !important;
}

.border-left {
	border-left-color: $border-color !important;
}

.border-right {
	border-right-color: $border-color !important;
}

.border-bottom {
	border-bottom-color: $border-color !important;
}

 /* badge  */
.badge {
	border-radius: $border-radius;

	+ {
		.badge {
			margin-left: 6px;
		}

	}

}

.badge-md {
	padding: 5px 8px;
	font-size: 13px;
	font-weight: normal;
}

.badge-primary {
	background: $primary;
}

 /* back-to-to */
.back-to-top {
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 50px;
		right: 40px;
		width: 50px;
		height: 40px;
		margin: 0px;
		color: $white;
		font-size: 18px;
		background: $primary;
		color: $secondary;
		transition: all 0.3s ease-in-out;
		z-index: 999;
   transform: skew(-20deg);

   i {
   	transform: skew(20deg);

   }

	&:hover {
		color: $white;
	}

	&:focus {
		color: $white;
	}
}
}

/* breadcrumb */
.breadcrumb {
	margin-top: 20px;
	background: transparent;
	border: 2px solid rgba($white, 0.3);
	font-family: $font-hedding;
	font-weight: 500;
	border-radius: 0;
	display: inline-flex;
	transform: skew(-20deg);
	padding: 14px 40px;

	.breadcrumb-item {
		padding-right: 5px;
		transform: skew(20deg);
		display: inline-block;

		a {
			color: $white;

			&:hover {
				color: $primary;
			}

		}

		&:before {
			color: $white;
		}

	}

	.breadcrumb-item.active {
		color: $primary;
	}

}

/* pagination */
.pagination {
	.page-item {
		.page-link {
			padding: 14px 28px;
			color: $gray-3;
			border-color: $border-color;
			margin: 0 4px;
			transform: skew(-20deg);

			span {
				transform: skew(20deg);
				display: block;
			}

			&:focus {
				box-shadow: none;
			}

		}

	}

	.page-item.active {
		.page-link {
			background: $primary;
			border-color: $primary;
			color: $secondary;
		}

	}

	.page-item:first-child .page-link {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.page-item:last-child .page-link {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

}

/* blockquote */
.blockquote {
	font-size: 16px;
	border-left: 10px solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
}

.blockquote-quote {
	position: relative;
	font-size: 18px;
	border-left: 0 solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
	z-index: 1;

	p {
		position: relative;
	}

	i {
		position: absolute;
		z-index: 0;
		top: 20px;
		left: 0;
		font-size: 60px;
		line-height: 60px;
		color: $gray-2;
	}

}

iframe {
	width: 100%;
}

/* table */

.table {
	border-collapse: separate;
	border-spacing: 0 1px;
	font-size: 16px;
	font-weight: 600;

	tr,
	thead {
	}

	td {
		padding: 14px 20px;
	}

	th {
		padding: 14px 20px;
	}

}

.table-bordered {
	td {
		border-color: $border-color;
	}

	th {
		border-color: $border-color;
	}

}

.table-striped {
	tbody {
		tr {
			&:nth-of-type(odd) {
				background: $gray-1;
			}

		}

	}

}

.table td,
.table th {
	vertical-align: middle;
}

.table-skew {
	padding: 0 30px;

	thead {
		transform: skew(-20deg);
		tr {
			transform: skew(0deg);
		}
	}

	tr {
		transform: skew(-20deg);
	}

	td {
		transform: skew(20deg);
	}

	th {
		transform: skew(20deg);
	}

}
