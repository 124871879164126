/*****************************
  Team
*****************************/
.team {
	position: relative;

	.team-info {
		padding: 20px 36px;
		display: flex;
	}

	.share {
		margin-left: auto;

		.share-icon {
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			transform: skew(-20deg);
			background: $secondary;
			color: $white;
			display: block;

			i {
				transform: skew(20deg);
			}

		}

		.share-box {
			position: absolute;
			bottom: 40px;
			opacity: 0;
			visibility: hidden;
			transition: all 0.5s ease-in-out;

			li {
				a {
					width: 50px;
					height: 50px;
					line-height: 50px;
					text-align: center;
					transform: skew(-20deg);
					background: $primary;
					color: $secondary;
					display: block;

					i {
						transform: skew(20deg);
					}

					&:hover {
						background: $secondary;
						color: $primary;
					}

				}

			}

		}

		&:hover {
			.share-box {
				opacity: 1;
				bottom: 56px;
				visibility: visible;
			}

		}

	}

}
