// Font family
$font-base: 		'Bai Jamjuree', sans-serif;;
$font-hedding:	'Bai Jamjuree', sans-serif;

// Colors
$body-color:		#666666;
$primary:				#f3f526;
$secondary:			#1947c1;
$white:					#ffffff;
$gray-1: 				#f8f8f8; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#666666; 	// body-text
$gray-4: 				#707173;
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#1d2936;
$gray-8: 				#333333; 	// Hedding color
$gray-9: 				#18191c; 	// bg color
$black:  				#000000;
$success:				#0abb0a;
$danger:				#f52626;

$border-color: 	#ebebeb;

// For button and input border radius
$border-radius: 3px;
