/*****************************
  Shortcodes
*****************************/

/*****************************
  Accordion
*****************************/

.accordion {
	.card{
		border: none;
		padding: 0 12px;
		.card-header{
			background: none;
			border: 1px solid $border-color;
			padding: 0;
			margin-bottom: 20px;
			transform: skew(-20deg);
			.btn {
    		transform: skew(20deg);
			}
			&:before {
			border-bottom: 1px solid $border-color;
			}
			button{
				position: relative;
				font-size: 16px;
				color: $gray-8;
				padding:17px 30px 17px 30px;
				width: 100%;
				text-align: left;
				border: none;

			}
		}
		.card-body {
			padding: 10px 30px 30px 30px;
		}
	}
}
.accordion .card .accordion-icon.card-header button.collapsed:after {
	background: $white;
}


/* accordion-02 */

.accordion.accordion-02{
	.card{
		padding:8px 0px;
		margin-bottom: 14px;
	.card-header{
		margin-bottom: 0;
		border:none;
		button{
			font-size: 14px;
			padding: 0px 0px 0px 30px;
			&:before{
             line-height: 12px;
             font-size: 8px;
             position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					content: "\f068";
					font-family: "Font Awesome 5 Free";
					border: 1px solid $gray-9;
					width: 15px;
					height: 15px;
					line-height: 12px;
					border-radius: 50%;
					z-index: 9;
					text-align: center;
			}
			&:after{
				display: none;
		}
	  }
	}
	.card-body {
		padding: 10px 0px 0px 30px;
	}

  }
}

.accordion .accordion-icon.card-header button.collapsed:before{
	content: "\f067";
}
