/*****************************
  Newsletter
*****************************/
.newsletter {
	position: relative;

	.form-control {
		margin-right: 150px;
		transform: skew(-20deg);
		height: 50px;
	}

	.btn {
		position: absolute;
		right: 0;
		line-height: 20px;
		top: 0;
		padding: 14px 30px;
	}

}
