/*****************************
    Banner
*****************************/

.banner {

	.banner-content {
		padding: 160px 0;
		position: relative;
		z-index: 1;
	}

	.banner-title {
		font-size: 90px;
		line-height: 100px;
	}

	.banner-image {
		position: absolute;
		top: 40px;
		right: 0;
		z-index: 0;
	}

	.banner-shape {
		right: 0px;
		position: absolute;
		bottom: -30px;
	}

}

/* Banner home */

.banner-home {
	margin-bottom: -20px;
	padding-bottom: 20px;

	.swiper-container {
		padding-bottom: 45px;
	}
}


/* Banner shop */
.banner-shop {

	padding: 300px 0;
	height: 90vh;
	position: relative;

	.banner-shop-image {
		position: absolute;
		left: 3%;
		bottom: 0;
		z-index: 1;
	}

	.banner-shop-shape {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
	}

	.banner-shop-shape-02 {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 0;
	}

}

/* Banner location */
.banner-location {
	padding: 160px 0;
}

/* Slider product */
.slider-product {
	position: relative;

	&:before {
		position: absolute;
		content: "";
		width: 45%;
		height: 100%;
		top: 0;
		left: -10%;
		background: $gray-1;
		z-index: -1;
		transform: skew(-20deg);
	}

	.slider-tagliine {
		font-family: 'Yellowtail', cursive;
		font-size: 120px;
		line-height: 140px;
	}

	.slider-shape {
		position: relative;

		&:before {
			position: absolute;
			content: "";
			width: 70%;
			height: 60%;
			top: 21%;
			background: $primary;
			z-index: -1;
			transform: skew(-20deg);
		}

	}

}

.banner .icon-btn {
	display: flex;
  width: 70px;
  height: 60px;
  text-align: center;
  background: $gray-2;
  align-items: center;
  align-self: center;
  justify-content: center;
  color: $secondary;
  font-size: 30px;
	transform: skew(-20deg);

	&:hover {
  background: $primary;
	}

		&:focus {
  		outline: none;
  		box-shadow: none;
	}
}


.banner {
	.swiper-button-prev.icon-btn {
		left: 100px;

		i {
			margin-left: -90px;
			font-size: 50px;
			transform: skew(20deg);
		}

	}

}

.banner {
	.swiper-button-next.icon-btn {
		right: 100px;

		i {
			margin-right: -90px;
			font-size: 50px;
			transform: skew(20deg);
		}

	}

}

.banner-home .swiper-container-horizontal {
	.swiper-pagination-bullets {
		right: 60px !important;
		left: auto;

		.swiper-pagination-bullet {
			margin: 5px 4px;
			display: block;
			width: 16px;
			height: 16px;
			left: inherit !important;
			border-radius: 0;
			transform: skew(-20deg);
		}

	}

}

.banner-home .swiper-pagination-bullet {
	background: $white;
	opacity: 0.5;
	box-shadow: none;
	outline: none;
}

.banner-home .swiper-pagination-bullet-active {
	background: $primary;
	opacity: 1;
}

.banner-home .swiper-container-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
	left: inherit;
	top: 50%;
	transform: inherit;
	width: auto !important;
}

.swiper-button-next, .swiper-button-prev {
	z-index: 8;
}
