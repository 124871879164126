/*****************************
  Countdown
*****************************/
.countdown {
	display: inline-block;
	text-align: center;
	margin: 0px 20px;

	span {
		font-size: 80px;
		line-height: 80px;
		font-weight: 600;
		color: $primary;
	}

	p {
		font-size: 20px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $gray-8;
	}

}

.countdown-small {
	span {
		font-size: 24px;
		line-height: 24px;
	}

	p {
		font-size: 14px;
	}

}
.coming-soon.newsletter{
	.form-control{
    padding-right:185px;
	}
}
