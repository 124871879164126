/*****************************
  Feature box
*****************************/
.feature-box {
	transform: skew(-20deg);
	transition: all 0.4s ease-in-out;

	i,
	h6 {
		transform: skew(20deg);
	}

	&:hover {
		background: $primary;

	i {
		color: $secondary;
	}

	}

}

.feature-box.active {
	background: $primary;

	i {
		color: $secondary;
	}

}
