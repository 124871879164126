/*****************************
	Progress Bar
*****************************/
.progress {
	background: $gray-1;
}

.progress .progress-bar {
	background: $secondary;
}

