
/*****************************
  		Sidebar
*****************************/
.categorize {
	li {
		a {
			background: $gray-1;
			color: $secondary;
			padding: 16px 30px;
			display: block;
			margin-bottom: 3px;
			font-size: 16px;
			transform: skew(-20deg);
			font-weight: 600;

			span {
				transform: skew(20deg);
				display: block;
			}

		}

		a.active {
			background: $primary;
			color: $secondary;
		}

	}

}
