
/*****************************
		Select
*****************************/
.select2-container {
	.select2-selection--single {
		height: auto;
		outline: none;

		.select2-selection__rendered {
			padding-left: 0px;
		}

	}

}

.select2-container--default {
	.select2-selection--single {
		border-radius: 0px;
		border: none;

		.select2-selection__rendered {
			color: $gray-8;
			font-weight: bold;
			line-height: inherit;
			text-transform: capitalize;
		}

	}

}
.bigdrop {
	min-width: 196px !important;
}

.select2-results__options {
	padding: 8px 2px;
}

.select2-container--default .select2-results__option--highlighted {
	border-radius: 0;
	transition: all 0.2s ease-in-out;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	padding-left: 10px;
	font-weight: 600;
}

/* Select Dropdown Arrow */
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			top: 14px;
			right: 10px;
			height: 21px;
			width: 14px;

			b {
				border: none;
			}

			&:before {
				content: "\f078";
				font-family: "Font Awesome 5 Free";
				font-style: normal;
				font-weight: 900;
				color: $primary;
			}

		}

	}

	.select2-search--dropdown {
		padding: 0;

		.select2-search__field {
			border: 1px solid $border-color;
		}

	}

	.select2-results__option[aria-selected=true] {
		background-color: $gray-1;
	}

	.select2-results__option--highlighted[aria-selected] {
		background: rgba($primary, 0.1);
		color: $primary;
	}

}

.select2-dropdown {
	border: none;
	border-radius: 0px;
	z-index: 8;
}

.select-border {
	.select2-container--default {
		.select2-selection--single {
			border: 1px solid $border-color;
			height: 50px;
			padding: 15px 20px;
			border-radius: 0;

			.select2-selection__arrow {
				top: 16px;
				right: 20px;
			}

		}

	}

}

.select2-results__option[aria-selected] {
	text-transform: capitalize;
}

.select2-container--default .select2-results > .select2-results__options {
	border: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: $gray-5;
}

.select2-container--open .select2-dropdown {
	background: $gray-1;
	padding: 7px;
	border: 1px solid $border-color;
}

.select2-search--dropdown .select2-search__field {
	border: 1px solid $border-color;
	box-shadow: 0px 2px 5px rgba($black, 0.04);
}

.select2-container--default .select2-selection--single {
	height: 50px;
	padding: 15px 20px;
}
