/*****************************
	Gallery
*****************************/
.my-shuffle-container {
	margin-left: -12px;
	margin-right: -12px;

	.grid-item {
		width: 33.33%;
		padding: 15px;
	}

}

.portfolio-item {
	img {
		width: 100%;
		transform: scale(1);
		transition: all 0.3s ease;
	}

	.portfolio-img {
		margin-left: auto;

		i {
			font-size: 20px;
		}

	}

	.portfolio-category {
		margin-bottom: 5px;
		padding: 6px 20px;
		position: absolute;
		bottom: -6px;
		left: 20px;
		background: $white;

		a {
			font-size: 14px;
			font-weight: 600;
			color: $gray-8;
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}

		}

	}

	.portfolio-info {
		margin-top: 25px;
		margin-bottom: 35px;
		position: relative;
		display: flex;
		align-items: center;

		.portfolio-title {
			color: $gray-9;
			font-size: 18px;
			font-weight: 600;
			font-family: $font-hedding;
			display: block;

			&:hover {
				color: $primary;
			}

		}

	}

	&:hover {
		.portfolio-overlay {
			opacity: 1;
			background: rgba($black, 0.3);
		}

	}

}

.portfolio-item.portfolio-item-02 {
	overflow: hidden;

	.portfolio-info {
		padding: 20px;
		width: 90%;
		position: absolute;
		left: 50%;
		bottom: 0px;
		z-index: 2;
		opacity: 0;
		border-radius: 5px;
		transition: all 0.5s ease-in-out;
		transform: translate(-50%, 0%);
		background: $white;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	&:hover {
		.portfolio-info {
			opacity: 1;
			bottom: 30px;
		}

	}

}

.my-shuffle-container {
	.portfolio-item {
		.portfolio-info {
			margin-bottom: 0px;
		}

	}

}

.filters-group {
	display: table;
	margin: 0 auto 50px;
	text-align: center;

	button {
		margin: 0 1px 4px;
		padding: 12px 20px;
		cursor: pointer;
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
		color: $white;
		background: $secondary;
		border: none;
		transition: all 0.3s ease;
		transform: skew(-20deg);

		span {
			transform: skew(20deg);
			display: block;
		}

		&:hover {
			background: $primary;
			color: $white;
		}

	}

	button.active {
		background: $primary;
		color: $secondary;
	}

}

.bg-dark .portfolio-item {
	.portfolio-info {
		.portfolio-title {
			color: $white;

			&:hover {
				color: $primary;
			}

		}

	}

}

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.grid-style {
	.grid-item {
		padding: 0 !important;
	}

}

.my-shuffle-container.columns-1 {
	.grid-item {
		width: 100%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-2 {
	.grid-item {
		width: 50%;
		padding: 15px;
		float: left;
	}

}

.my-shuffle-container.columns-3 {
	.grid-item {
		width: 33.33333333%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-4 {
	.grid-item {
		width: 25%;
		padding: 10px;
	}

}

.my-shuffle-container.columns-5 {
	.grid-item {
		width: 20%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-6 {
	.grid-item {
		width: 16.666666%;
		padding: 15px;
	}

}

// masonry
.masonry.my-shuffle-container {
	.grid-item {
		padding: 15px;
		float: left;
	}

	.m-col-3 {
		width: 25%;
	}

	.m-col-4 {
		width: 40%;
	}

	.m-col-5 {
		width: 50%;
	}

	.m-col-6 {
		width: 50%;
	}

	.m-col-7 {
		width: 65%;
	}

}
@media (max-width:1200px) {

.my-shuffle-container.columns-6 {
	.grid-item {
		width: 25.00%;
		padding: 15px;
	}

}

}


@media (max-width:991px) {

.my-shuffle-container.columns-6 {
	.grid-item {
		width: 33.333%;
		padding: 15px;
	}

}
.my-shuffle-container.columns-5 {
	.grid-item {
		width: 33.333%;
		padding: 15px;
	}

}

}


@media (max-width:767px) {
	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 50%;
	}

}

@media (max-width:575px) {
	.my-shuffle-container.columns-2 .grid-item,
	.my-shuffle-container .grid-item {
		width: 100%;
	}

	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-2 .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 100%;
	}

}
