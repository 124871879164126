
/*****************************
    Layout
*****************************/

/* section title */
.section-title {
	margin-bottom: 50px;

	.sub-title {
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		position: relative;
	}

	.pre-title {
		font-size: 16px;
	}

	.title-shape {
		display: inline-block;
		width: 16px;

		&:before {
			content: "";
			background: $secondary;
			height: 20px;
			width: 8px;
			left: 0;
			top: 0;
			transform: skew(-20deg);
			position: absolute;
		}

		&:after {
			content: "";
			background: $primary;
			height: 20px;
			width: 8px;
			left: 8px;
			top: 0;
			transform: skew(-20deg);
			position: absolute;
		}

	}

}

/* Page title */

.page-title {
	padding: 140px 0;
}


/* About box */

.about-box-border {
	position: absolute;
	width: 105%;
	max-width: 105%;
	margin-left: -2.5%;
	height: 60%;
	bottom: 0;
	left: 0;
	border: 2px solid $primary;
	transform: skew(-20deg);
}


.map iframe.contact-map {
 height: 620px;
 width: 100%;
 border:0;
}


.map-box {
	iframe {
		border:0;
		width: 100%;
		height: 200px;
	}
}

/* Load more */

.more-row {
	display:none;
}
