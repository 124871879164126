/*****************************
  Button
*****************************/
.btn {
	transition: all 0.3s ease-in-out;
	text-transform: uppercase;
}

.btn-link {
	text-decoration: none !important;
}

button {
	outline: medium none !important;
	color: $primary;
}

/* btn */
.btn {
	font-weight: 700;
	font-size: 16px;
	padding: 12px 40px;
	border-radius: 0;


	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}

		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $white;
				}

			}

		}

	}

	&:hover {
		box-shadow: none;
		outline: none;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}

}

.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}

	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}

}

.btn-primary {
	background: $primary;
	border-color: $primary;
	color: $secondary;
	position: relative;
	&:hover {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
		color: $secondary;

		&:not(:disabled) {
			&:not(.disabled).active {
				background: $primary;
				border-color: $primary;
				color: $secondary;
			}

			&:not(.disabled) {
				&:active {
					background: $primary;
					border-color: $primary;
					color: $secondary;
				}

			}

		}

	}
	&:focus {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
		color: $secondary;
	}
&:focus {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
		color: $secondary;
	}

}

.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: $primary;
	border-color: $primary;
}


.btn-secondary {
	background: $secondary;
	border-color: $secondary;
	color: $white;
	position: relative;
	&:hover {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);

		&:not(:disabled) {
			&:not(.disabled).active {
				background: $secondary;
				border-color: $secondary;
			}

			&:not(.disabled) {
				&:active {
					background: $secondary;
					border-color: $secondary;
				}

			}

		}

	}
	&:focus {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);
	}
&:focus {
		background: darken($secondary, 6%);
		border-color: darken($secondary, 6%);
	}

}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
	background: $secondary;
	border-color: $secondary;
}

.btn-dark {
	background: $gray-9;
	border-color: $gray-9;

	&:hover {
		background: lighten($gray-9, 5%);
		border-color: lighten($gray-9, 5%);
	}

}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}

		}

	}

	i {
		padding-right: 10px;
	}

	+ {
		.btn {
			margin-left: 3px;
		}

	}

}

.btn-link {
	color: $primary;

	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-9;

	&:hover {
		background: $gray-2;
		border-color: $gray-2;
		color: $gray-9;
	}

	&:active {
		color: $gray-9;
	}

	&:focus {
		color: $gray-9;
	}

}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-light {
	&:active {
		color: $gray-9;
	}

	&:focus {
		color: $gray-9;
	}

}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn.btn-sm {
	padding: 6px 24px;
}

.btn.btn-md {
	padding: 9px 26px;
}

.btn.btn-lg {
	padding: 13px 30px;
}

.btn.btn-xl {
	padding: 16px 40px;
}

.btn-app {
	display: flex;

	i {
		font-size: 40px;
	}

}

.btn-outline-secondary {
	border: 2px solid $border-color;
	color: $gray-8;

	&:hover {
		background: $gray-8;
		color: $white;
		border-color: $gray-9;
	}

	&:focus {
		background: $gray-9;
		color: $white;
		border-color: $gray-9;
	}

}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
	background: $gray-9;
	color: $white;
	border-color: $gray-9;
}

/* btn-skew */

.btn-skew {
	transform: skew(-20deg);

	span {
		transform: skew(20deg);
		display: block;
	}

}

.play-video-lg {
	width: 100px;
	height: 100px;
	line-height: 100px;
	font-size: 16px;
	background: $white;
	color: $secondary;
	display: block;
	border-radius: 50%;
	text-align: center;
}


.play-video-md {
	width: 70px;
	height: 70px;
	line-height: 70px;
	font-size: 16px;
	background: $white;
	color: $secondary;
	display: block;
	border-radius: 50%;
	text-align: center;
}

