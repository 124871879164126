/*****************************
  Footer
*****************************/

.footer {
  .footer-tag {
    display: flex;
    flex-wrap: wrap;

    li {
      margin-right: 3px;
      margin-bottom: 3px;
      display: block;

      a {
        background: $black;
        color: $white;
        padding: 10px 16px;
        display: block;
        transform: skew(-20deg);

        span {
          transform: skew(20deg);
          display: block;
        }

        &:hover {
          background: $primary;
          color: $secondary;
        }

      }

    }

  }

.footer-bottom {
  padding: 40px 0;

  .footer-logo {
    height: 40px;
  }

}

}

.social-round {
  display: flex;

  li {
    a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      display: block;
      background: $primary;
      color: $secondary;
      border-radius: 50%;
      margin: 0 5px;
      font-size: 18px;

      &:hover {
        background: $secondary;
        color: $primary;
      }

    }

  }

}
